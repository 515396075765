<template>
    <b-modal
      ref="modal"
      :title="$t('PILLS.EXECUTION.time_end_title')"
      :ok-title="$t('ACTIONS.refresh')"
      :cancel-title="$t('ACTIONS.cancel')"
      @ok="refresh"
      @cancel="cancel"
    >
      <p>{{ $t('PILLS.EXECUTION.time_end_text') }}</p>
    </b-modal>
</template>

<script>
export default {
  name: 'NotifyPillTimeEnded',
  props: ['value'],
  methods: {
    refresh () {
      location.reload()
    },
    cancel () {
      this.$emit('input', false)
    }
  },
  watch: {
    value () {
      if (this.value === true) {
        this.$refs.modal.show()
      }
    }
  }
}
</script>
