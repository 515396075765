<template>
  <Card :class="{ completed: isComplete }" no-shadow>

    <template v-slot:title>
      <div class="mb-2" style="justify-content: space-between; display: flex; align-items: flex-start;">
          <h4 style="flex: 1;">{{ machine.name }}</h4>
          <DifficultyPill :difficulty="machine.difficulty" />
          <Pill v-if="hasOS" class="ml-2" :text="machine.os" :cssClasses="{ secondary: true }" />
          <StatusPill class="ml-2" :status="machine.status" />

          <Pill class="ml-2" v-if="isMachineBlockedByOther" :cssClasses="{ error : true }" v-b-tooltip="$t('LAB_MACHINE.locked_by_other')">
            <b-icon icon="lock" />
          </Pill>
      </div>
    </template>

    <template v-slot:content>

      <div>
        <span class="font-weight-bold">{{ $t('TERMS.objective') }}</span><br>
        <p v-html="translatedDescription"></p>
      </div>

      <div>
        <span class="font-weight-bold">{{ $t('MACHINES.connection_title') }}</span><br>
        <p v-if="machine.connectionInfo">{{ translatedConnectionInfo }}</p>
        <p v-else>{{ $t('MACHINES.no_connection_info') }}</p>

        <span v-if="machine.instanceIp" class="font-weight-bold">{{ $t('MACHINES.ip') }}</span><br v-if="machine.instanceIp">
        <span v-if="machine.instanceIp">{{ machine.instanceIp }}</span>
      </div>

      <div class="mt-3">
        <span class="font-weight-bold">{{ $t('TERMS.hints') }}</span><br>

        <ul class="custom" v-if="hints.length > 0">
          <li v-for="hint in hints" v-bind:key="hint.text">{{ hint.text }}</li>
        </ul>
        <span v-else-if="machine.hintsTotal === 0">{{ $t('HINTS.machine_has_no_hints') }}</span>
        <span v-else>{{ $t('HINTS.no_unlocked') }}</span>
      </div>

      <div style="clear: both"></div>
      <div class="mt-2">
        <span class="font-weight-bold">{{ $t('GENERAL.actions') }}</span><br>

        <span v-if="isMachineBlockedByOther">{{ $t('LAB_MACHINE.ACTIONS.no_actions_because_blocked_by_other_user') }}</span>
        <span v-else-if="isOtherMachineBlockedByMe">{{ $t('LAB_MACHINE.ACTIONS.no_actions_because_you_already_have_other_machine') }}</span>
        <b-button-group v-else>
          <RevertLabMachineModal class="mr-1" only-icon v-if="canRevertMachine" @success="machineRevertSuccess" @error="machineRevertError" :pill-id="pill.id" :machine-id="machine.id" />
          <StopLabMachineModal class="mr-1" only-icon v-if="canStopMachine && isAwsMachine" @success="machineStopSuccess" @error="machineStopError" :pill-id="pill.id" :machine-id="machine.id" />
          <LockLabMachine class="mr-1" only-icon v-if="canStartMachine && isVMWareMachine" @success="machineStartSuccess" @error="machineStartError" :pill-id="pill.id" :machine-id="machine.id" />
          <UnlockLabMachine class="mr-1" only-icon v-if="canStopMachine && isVMWareMachine" @success="machineStopSuccess" @error="machineStopError" :pill-id="pill.id" :machine-id="machine.id" />
          <StartLabMachine class="mr-1" only-icon v-if="canStartMachine && isAwsMachine" @success="machineStartSuccess" @error="machineStartError" :pill-id="pill.id" :machine-id="machine.id" />
          <InsertFlagModal class="mr-1" only-icon @success="insertFlagSuccess" :pill-id="pill.id" :machine-id="machine.id" v-if="canInsertFlag" />
          <UnlockHint class="mr-1" only-icon v-if="canUnlockMoreHints" @success="unlockHintSuccess" @error="unlockHintError" :pill-id="pill.id" :machine-id="machine.id"/>
        </b-button-group>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { LAB_MACHINE_STATUS, LAB_MACHINE_TYPE } from '@/config/constants'

import StatusPill from '@/components/utils/pills/StatusPill'
import DifficultyPill from '@/components/utils/pills/DifficultyPill'
import Pill from '@/components/utils/pills/Pill'

import InsertFlagModal from '@/components/labs/InsertFlag'
import RevertLabMachineModal from '@/components/labs/RevertLabMachine'
import StopLabMachineModal from '@/components/labs/StopLabMachine'
import StartLabMachine from '@/components/labs/StartLabMachine'
import LockLabMachine from '@/components/labs/LockLabMachine'
import UnlockLabMachine from '@/components/labs/UnlockLabMachine'
import UnlockHint from '@/components/labs/UnlockHint'

import ToastsMixin from '@/services/mixins/toasts.mixins'
import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'PillMachineInfo',
  mixins: [ToastsMixin, i18nmixin],
  components: { DifficultyPill, StatusPill, InsertFlagModal, RevertLabMachineModal, StopLabMachineModal, StartLabMachine, LockLabMachine, UnlockLabMachine, UnlockHint, Pill },
  props: {
    pill: { type: Object, required: true, default: () => {} },
    machine: { type: Object, required: true, default: () => {} }
  },
  methods: {
    ...mapActions({
      addRevealedHint: 'pills/addRevealedHint',
      updatePillVms: 'pills/updatePillMachines'
    }),
    machineRevertSuccess () {
      return this.updatePillVms(this.pill.id)
        .then(_ => this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.revert_success')))
        .catch(_ => this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.revert')))
    },
    machineRevertError () {
      this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.revert'))
    },
    machineStartSuccess () {
      return this.updatePillVms(this.pill.id)
        .then(_ => this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.start_machine_success')))
        .catch(_ => this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.start')))
    },
    machineStartError () {
      this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.start'))
    },
    machineStopSuccess () {
      return this.updatePillVms(this.pill.id)
        .then(_ => this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.stop_success')))
        .catch(_ => this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.stop')))
    },
    machineStopError () {
      this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.stop'))
    },
    insertFlagSuccess () {
      return this.updatePillVms(this.pill.id)
        .catch(_ => this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.general')))
    },
    unlockHintSuccess (hint) {
      this.addRevealedHint({ hint: hint, machine: this.machine })
      this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.UNLOCK_HINT_success'))
    },
    unlockHintError () {
      this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.UNLOCK_HINT'))
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),
    assignedToCurrentUser () { return this.machine.user === this.currentUser.username },
    hasOS () { return typeof this.machine.os !== 'undefined' && this.machine.os !== null && this.machine.os !== '' },
    isComplete () { return this.machine.status === LAB_MACHINE_STATUS.COMPLETE },
    isInProgress () { return this.machine.status === LAB_MACHINE_STATUS.IN_PROGRESS },
    isPending () { return this.machine.status === LAB_MACHINE_STATUS.PENDING },
    isMachineOn () { return !!this.machine.instanceIp },

    translatedConnectionInfo () { return this.getTranslatedValue(this.machine, 'connectionInfo') },
    translatedDescription () { return this.getTranslatedValue(this.machine, 'description') },

    hints () { return this.translateObjectArray(typeof this.machine.hintsRevealed !== 'undefined' ? this.machine.hintsRevealed : []) },
    unlockedHints () { return this.machine.hintsRevealed.length },
    canUnlockMoreHints () { return (this.isInProgress) && this.unlockedHints < this.machine.hintsTotal },

    machineType () { return typeof this.machine.source !== 'undefined' ? this.machine.source : LAB_MACHINE_TYPE.AWS },
    isAwsMachine () { return this.machineType === LAB_MACHINE_TYPE.AWS },
    isVMWareMachine () { return this.machineType === LAB_MACHINE_TYPE.VMWARE },

    isMachineBlocked () { return typeof this.machine.isBlocked !== 'undefined' ? this.machine.isBlocked : false },
    isMachineBlockedByMe () { return typeof this.machine.isBlockedByYou !== 'undefined' ? this.machine.isBlockedByYou : false },
    isMachineBlockedByOther () { return this.isMachineBlocked && !this.isMachineBlockedByMe },
    isOtherMachineBlockedByMe () { return typeof this.machine.hasOtherMachineBlocked !== 'undefined' ? this.machine.hasOtherMachineBlocked : false },

    // Machine actions conditions
    // Start
    canStartMachine () { return this.isAwsMachine ? this.canStartAwsMachine : this.canStartVMWareMachine },
    canStartAwsMachine () { return !this.isPending && !this.isMachineOn },
    canStartVMWareMachine () { return !this.isOtherMachineBlockedByMe && !this.isMachineBlocked },

    // Revert
    canRevertMachine () { return (this.isVMWareMachine && this.canRevertVMWareMachine) || (this.isAwsMachine && this.canRevertAwsMachine) },
    canRevertVMWareMachine () { return this.isMachineBlockedByMe },
    canRevertAwsMachine () { return !this.isPending && this.isMachineOn },

    // Insert flag
    canInsertFlag () { return (this.isVMWareMachine && this.canInsertFlagVMWareMachine) || (this.isAwsMachine && this.canInsertFlagAwsMachine) },
    canInsertFlagAwsMachine () { return this.isInProgress && this.isMachineOn },
    canInsertFlagVMWareMachine () { return !this.isComplete }, // VMWare machines can insert flag at all times, except when they are finished

    // Stop machine
    canStopMachine () { return (this.isAwsMachine && this.canStopAwsMachine) || (this.isVMWareMachine && this.canStopVMWareMachine) },
    canStopAwsMachine () { return !this.isPending && this.isMachineOn },
    canStopVMWareMachine () { return this.isMachineBlockedByMe }
  }
}
</script>

<style lang="scss">
.tooltip {
  white-space: pre-wrap;
}

.completed {
  border-left: 3px solid $green-dark !important;
}
</style>
