<template>
  <b-button class="countdown-wrapper" variant="outline-secondary">
    {{ formattedTime }}
  </b-button>
</template>

<script>
export default {
  name: 'Countdown',
  data () {
    return {
      intervalId: null,
      currentSeconds: 0
    }
  },
  props: {
    minutes: { type: Number, required: true }
  },
  watch: {
    minutes () {
      this.currentSeconds = this.minutes * 60
    }
  },
  methods: {
    tick () {
      if (this.currentSeconds === 0) {
        this.notify()
        this.stop()
        return
      }
      this.currentSeconds = this.currentSeconds - 1
    },
    notify () {
      this.$emit('finish')
    },
    stop () {
      if (this.intervalId === null) return

      clearInterval(this.intervalId)
    }
  },
  computed: {
    totalSeconds () { return this.minutes * 60 },
    formattedTime () { return new Date(this.currentSeconds * 1000).toISOString().substr(11, 8) }
  },

  mounted () {
    this.currentSeconds = this.minutes * 60
    this.intervalId = setInterval(this.tick, 1000)
  },
  beforeDestroy () {
    this.stop()
  }
}
</script>

<style lang="scss" scoped>

</style>
