<template>
  <div>
    <loading :loading="somethingIsLoading"></loading>
      <div class="pill-top-header">
        <a class="clickable back-link" @click="$router.go(-1)">
          <b-icon icon="arrow-left-circle-fill" variant="white" class="mr-2 mt-2" font-scale="2"/>
        </a>

        <div ref="resourcesContainer" class="resources-tab-wrapper ml-4" v-if="resources.length <= 3">
          <div
            v-for="(resource, index) in resources"
            v-bind:key="resource.id"
            @click="switchResource(index)"
            class="clickable resource-tab mr-3"
            :class="{ active: selectedResource.id === resource.id && showPillResources }"
          >
            <div class="flex flex-row align-items-center">
              <font-awesome-icon class="mr-2" :icon="getIconForResourceType(resource.type)" size="1x" />
              <span>{{ resource.name }}</span>
            </div>
          </div>
        </div>

        <b-dropdown class="ml-4" v-if="resources.length > 3">
          <template #button-content>
            <span v-if="selectedResource.name">
              <font-awesome-icon class="mr-2" :icon="getIconForResourceType(selectedResource.type)" size="1x" />
              <span>{{ selectedResource.name }}</span>
            </span>

            <span v-else>{{ $t('GENERAL.no_selection') }}</span>
          </template>
          <b-dropdown-item
            v-for="(resource, index) in resources"
            v-bind:key="resource.id"
            @click="switchResource(index)"
            class="clickable resource-tab mr-3"
            :class="{ active: selectedResource.id === resource.id && showPillResources }"
          >
            <font-awesome-icon class="mr-2" :icon="getIconForResourceType(resource.type)" size="1x" />
            <span>{{ resource.name }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <div class="resources-tab-wrapper">
          <div
            v-for="(vm, index) in vms"
            v-bind:key="vm.name"
            @click="switchVm(index)"
            class="clickable resource-tab mr-3"
            :class="{ active: selectedVm.id === vm.id && showPillResources }"
          >
            <div class="flex flex-row align-items-center">
              <font-awesome-icon class="mr-2" :icon="['fa', 'server']" size="1x" />
              <span>{{ vm.name }}</span>
            </div>
          </div>
        </div>

        <div class="pt-3 pr-4" style="flex: 1; text-align: right; color: white;">

          <div class="h-100 mr-3" style="display: inline-block;" v-if="hasVms">
            <b-button-group size="md">
              <b-button v-b-tooltip.hover.bottom="$t('TERMS.resources')" :variant="showPillResources ? 'secondary' : 'outline-secondary'" @click="resourceOrMachine = $t('TERMS.resources')">
                <font-awesome-icon :icon="['fas', 'book-open']" />
              </b-button>
              <b-button
                v-if="canSeeUserConsole"
                v-b-tooltip.hover.bottom="$t('TERMS.console')" :variant="showPlayerMachine ? 'secondary' : 'outline-secondary'" @click="resourceOrMachine = $t('TERMS.console')">
                <b-icon icon="terminal-fill" variant="secondary" />
              </b-button>
            </b-button-group>
          </div>

          <b-button variant="outline-secondary" @click.stop="forceRefocus" class="mr-3 no-hover" v-if="!isStudentMachineLoading && consoleUrl" v-b-tooltip.hover.bottom="guacamoleFocused ? $t('STUDENT_MACHINE.iframe_has_focus') : $t('STUDENT_MACHINE.iframe_doesnt_has_focus')">
            <font-awesome-icon  :icon="['fas', 'crosshairs']" class="focus-indicator" :class="{ focus: guacamoleFocused}" />
          </b-button>

          <b-button variant="secondary" class="mr-3" v-b-tooltip.hover.bottom="infoCollapsed ? $t('ACTIONS.expand') : $t('ACTIONS.collapse')" @click="contentExpanded = !infoCollapsed; infoCollapsed = !infoCollapsed">
            <font-awesome-icon :icon="['fa', infoCollapsed ? 'expand-alt' : 'compress-alt']" color="white" />
          </b-button>

          <b-form-radio-group
              id="btn-radios-info-quiz"
              v-model="showingInfo"
              :options="[{ text: 'Info', value: true }, { text: 'Quiz', value: false }]"
              buttons
              button-variant="outline-secondary"
              size="md"
              name="radio-btn-outline"
              v-if="shouldShowInfo"
            ></b-form-radio-group>

          <Countdown
            v-if="hasResources"
            class="ml-3"
            v-b-tooltip.hover.bottom="$t('PILLS.EXECUTION.timer_explanation')"
            :minutes="pillDuration"
            @finish="countdownFinished" />
          <NotifyPillTimeEnded v-model="showNotifyPillTimeEnd" />
        </div>
      </div>
    <div class="content-wrapper flex flex-column justify-content-center" :class="{ expanded: contentExpanded }">
      <div
      class="flex flex-column justify-content-center"
      v-for="resource in resources"
      v-bind:key="resource.id"
      :class="{ 'h-100': selectedResource.id === resource.id && resource.type !== 'video', 'video-wrapper': resource.type === 'video'}"
      v-show="showPillResources"
      >
        <div v-if="resourceLoading && selectedResource.id === resource.id" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>

        <video class="" controls preload="none" v-else-if="resource.type === 'video'" v-show="!resourceLoading && selectedResource.id === resource.id" :key="resource.url">
            <source :src="resource.url">
            {{ $t('ERRORS.video_not_suported') }}
        </video>

        <div
          v-else-if="resource.type === 'zip'"
          v-show="!resourceLoading && selectedResource.id === resource.id"
          class="text-center"
        >

          <NoResults img="/media/img/download_image.png" :title="$t('ACTIONS.download')" :msg="$t('PILLS.EXECUTION.donwload_description')" />
          <a class="btn btn-primary" download :href="resource.url">
            {{ $t('ACTIONS.download') }}
          </a>
        </div>

        <iframe
          v-else

          :src="resource.url"
          :ref="`resource_${resource.id}`"
          width="100%" height="100%"
          frameborder="0"
          v-show="!resourceLoading && selectedResource.id === resource.id"
          :key="'cyberacademy-iframe-' + resource.url"
        >
          <loading :loading="true" />
        </iframe>
      </div>

      <div
        class="flex flex-column justify-content-center"
        v-for="vm in vms"
        v-bind:key="vm.name"
        :class="{ 'h-100': selectedVm.id === vm.id}"
        v-show="showPillResources"
        >
        <div v-show="!resourceLoading && selectedVm.id === vm.id">
          <b-row class="justify-content-center">
            <b-col cols="12" md="12" lg="8" xl="6">
              <PillMachine :machine="translateObject(vm)" :pill="pill" />
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- Player machine -->
      <div class="flex flex-column justify-content-center h-100 p-relative" v-show="showPlayerMachine">
        <div v-if="loadingConsole" class="text-center flex align-items-center flex-column">
          <b-progress variant="success" animated :value="loadProgress" style="width: 200px;" />
          <span class="mt-2" style="display: block;">
            {{ currentConsoleStatus }}
          </span>
        </div>

        <iframe  id="consola" ref="consola" :src="consoleUrl" width="100%" height="100%" frameborder="0" v-if="!loadingConsole" >
          <loading :loading="true" />
        </iframe>

        <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0" @click.self.stop="refocusGuacamole(true)" v-show="!guacamoleFocused">

        </div>
      </div>

      <NoResults :title="$t('PILLS.EXECUTION.no_resources')" :msg="$t('PILLS.EXECUTION.no_resources_desc')" v-if="!somethingIsLoading && !resourceLoading && !hasResources && !hasVms && !showPlayerMachine" />
    </div>

    <div class="quiz-wrapper" :class="{ collapsed: infoCollapsed }">
      <div v-if="showingInfo" class="py-3">
        <div v-html="pill.description">
        </div>
      </div>

      <div v-else>
        <Card v-if="hasQuestions">
          <template v-slot:content>
            <span >{{ $t('PILLS.EXECUTION.progress') }}</span>
            <b-progress :value="quizProgress" :max="100"></b-progress>
          </template>
        </Card>

        <Card class="mt-3" v-else>
          <template v-slot:content>
            <p>{{ $t('PILLS.EXECUTION.no_questions_description') }}.</p>
          </template>
        </Card>

        <Card
          class="mt-3"
          v-for="(question) in form" v-bind:key="question.id"
        >

        <template v-slot:content>
          <b-form-group
            :id="`input-group-${question.id}`"
            :label="question.title"
            :label-for="`input-${question.id}`"
          >
            <b-form-input
              :id="`input-${question.id}`"
              :name="`input-${question.id}`"
              v-model="question.student_answer"
              :state="question.state"
              :type="question.type"
              :aria-describedby="`input-${question.id}-feedback`"
              required
              :value="question.student_answer"
              v-if="question.type !== 'select'"
            ></b-form-input>
            <b-form-select
              :id="`input-${question.id}`"
              :name="`input-${question.id}`"
              v-model="question.student_answer"
              text-field="text"
              :options="parseOptions(question.pill_question_options)"
              value-field="id"
              label-field="text"
              :state="question.state"
              v-else
            ></b-form-select>
            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback :id="`input-${question.id}-feedback`">
              {{ $t('PILLS.EXECUTION.invalid_answer') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </template>
        </Card>

        <b-button variant="primary" class="mt-5 mb-3" @click="submit" block>{{ submitButtonText }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import NoResults from '@/components/utils/NoResults'

import ApiService from '@/services/api.service'
import i18nservice from '@/services/i18n.service'
import URLS from '@/config/urls'
import { PILL_STATUS, PILL_TYPES, STUDENT_MACHINE_START_STATUS, STUDENT_MACHINE_BOOT_STATUS } from '@/config/constants'

import Countdown from '@/components/pill/CountdownTimer'
import NotifyPillTimeEnded from '@/components/pill/NotifyPillTimeEnded'

// Lab machine actions
import PillMachine from '@/components/pill/PillMachineInfo'

import ToastsMixin from '@/services/mixins/toasts.mixins'
import ErrorsMixin from '@/services/mixins/errors.mixins'
import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'PillExecution',
  components: { NoResults, PillMachine, Countdown, NotifyPillTimeEnded },
  mixins: [ToastsMixin, ErrorsMixin, i18nmixin],
  data () {
    return {
      loading: false,
      resourceOrMachine: this.$t('TERMS.resources'),
      url: '',
      resourceLoading: false,
      selectedResource: {},
      selectedVm: {},
      contentExpanded: false,
      infoCollapsed: false,
      showingInfo: false,
      guacamoleFocused: false,
      correct: 0,
      answers: {},
      form: [],

      checkStatusIntervalId: null,
      simulateProgressInterval: null,
      keepAliveInterval: null,
      consoleStartStatus: '',
      consoleStatus: '',
      consoleBoot: '',
      loadProgress: 0,
      loadingConsole: false,

      showNotifyPillTimeEnd: false
    }
  },
  watch: {
    resourceOrMachine () {
      if (this.showPlayerMachine && this.consoleUrl === '') {
        // TODO Perform Guaca request
        this.startStudenMachine()
      }
    }
  },
  methods: {
    ...mapActions({
      startConsole: 'labs/startConsole',
      getPillDetail: 'pills/getExecutionPillDetail',
      getPillResource: 'pills/getPillResourceUrl',
      checkConsoleStatusAction: 'labs/checkConsoleStatus',
      getOnlyUser: 'auth/getOnlyUser'
    }),
    countdownFinished () {
      this.showNotifyPillTimeEnd = true
    },
    startStudenMachine () {
      this.loadingConsole = true
      this.consoleStartStatus = 'starting'
      this.startCheckConsole()

      return this.startConsole()
        .then(response => {
          if (typeof response.data.data.url === 'undefined') throw Error('missing_url')

          this.url = response.data.data.url
          this.consoleStartStatus = 'started'
        })
        .catch(_ => {
          this.showErrorToast(this.$t('LABS.ERRORS.general'))
          this.stopCheckConsole()
        })
    },
    getIconForResourceType (type) {
      switch (type) {
        case 'pdf': {
          return ['fas', 'file-pdf']
        }
        case 'video': {
          return ['fas', 'play']
        }
        case 'zip': {
          return ['fas', 'file-archive']
        }
        default: {
          return ['fas', 'book-open']
        }
      }
    },
    switchVm (index) {
      if (this.vms.length === 0) return
      this.selectedVm = this.vms[index]

      // Clear selected resource selection
      this.selectedResource = {}

      // Move the resourse or machine selector if needed
      if (this.hasVms) this.resourceOrMachine = this.$t('TERMS.resources')
    },
    switchResource (index) {
      if (!Array.isArray(this.resources) || this.resources.length === 0) return
      this.selectedResource = this.resources[index]

      // Clear selected resource selection
      this.selectedVm = {}

      // Move the resourse or machine selector if needed
      if (this.hasVms) this.resourceOrMachine = this.$t('TERMS.resources')

      if (typeof this.selectedResource.url === 'undefined' || this.selectedResource.url === null || this.selectedResource.url === '') {
        this.resourceLoading = true

        this.getPillResource({ pill: this.pillId, resource: this.selectedResource.id })
          .then(url => { this.selectedResource.url = url })
          .catch(_ => { })
          .then(_ => { this.resourceLoading = false })
          .then(_ => { this.$forceUpdate() })
      }
    },
    parseOptions (data) {
      if (typeof data === 'string') return JSON.parse(data)
      if (Array.isArray(data)) return data
      return []
    },
    buildAnswerJson () {
      return this.form.map(item => {
        return { id: item.id, answer: item.student_answer || '' }
      })
    },
    mapAnswers (answers) {
      var indexes = answers.map(item => item.id)
      var correctAnswers = 0

      this.form.forEach(item => {
        item.state = answers[indexes.indexOf(item.id)].correct
        if (item.state) correctAnswers += 1
      })

      this.correct = correctAnswers
    },
    forceRefocus () {
      this.refocusGuacamole(true)
    },
    refocusGuacamole (force = false) {
      // var shouldForce = typeof force === 'object' ? false : Boolean(force)
      var shouldForce = typeof force === 'boolean' ? force : false

      // Do not refocus if focus is on an input field
      var focused = document.activeElement
      if (!shouldForce && focused && focused !== document.body) {
        this.guacamoleFocused = false
        return
      }

      // Ensure iframe is focused
      if (typeof this.$refs.consola !== 'undefined') this.$refs.consola.focus()
      this.guacamoleFocused = true
    },
    submit () {
      this.loading = true
      return ApiService.post(URLS.API.PILLS.SUBMIT_PILL(this.pill.id), this.buildAnswerJson())
        .then(response => {
          if (response.status !== 200 || response.data.status === 'error') throw response
          if (response.data.map(item => item.correct).every(Boolean)) {
            this.$router.push({ name: 'pillExecutionResult', params: { ...this.$route.params } })
          } else {
            this.mapAnswers(response.data)
            // Needed, as we have changed non-reactive keys
            this.$forceUpdate()
          }
        })
        .catch(err => {
          console.log(err.status, err.response)
          switch (err.data.data) {
            case 'errors.pills.cannot_complete_because_has_pending_vms': {
              this.showErrorToast(this.$t('PILLS.ERRORS.has_pending_vms'))
              break
            }
            default: {
              this.showErrorToast(this.$t('PILLS.ERRORS.general'))
            }
          }
        })
        .then(_ => { this.loading = false })
    },

    checkConsoleStatus () {
      if (this.consoleStartStatus === 'starting') return
      if (this.requestingConsoleStatus) return

      this.requestingConsoleStatus = true

      return this.checkConsoleStatusAction()
        .then(data => {
          this.parseConsoleStatus(data)
        })
        .catch(_ => {})
        .then(_ => { this.requestingConsoleStatus = false })
    },
    parseConsoleStatus (data) {
      if (typeof data.status === 'undefined' || !data.status) return
      if (typeof data.data === 'undefined') return

      this.consoleStatus = data.data.state
      this.consoleBoot = data.data.boot

      if (this.consoleStatus === STUDENT_MACHINE_START_STATUS.RUNNING && this.consoleBoot === STUDENT_MACHINE_BOOT_STATUS.RUNNING) {
        this.$emit('console-started')
      }
    },
    simulateProgress () {
      if (this.loadProgress < 90) this.loadProgress = this.loadProgress + 1
    },
    clearLoadingProgress () {
      this.loadProgress = 0
    },
    startCheckConsole () {
      this.checkStatusIntervalId = setInterval(this.checkConsoleStatus, 3000)
      this.simulateProgressInterval = setInterval(this.simulateProgress, 1000)
    },
    stopCheckConsole () {
      this.loadProgress = 100

      clearInterval(this.checkStatusIntervalId)
      clearInterval(this.simulateProgressInterval)

      setTimeout(this.clearLoadingProgress, 1500)

      this.guacamoleFocused = true

      // We give a small margin to avoid strange states
      setTimeout(_ => { this.loadingConsole = false }, 1000)
    },
    consoleKeepAlive () {
      this.keepAliveInterval = setInterval(this.getOnlyUser, this.consoleKeepAliveTimeInterval * 60 * 1000)
    }
  },
  computed: {
    ...mapGetters({
      requestedPill: 'pills/getPillDetail',
      isLoading: 'pills/isLoading',
      canSeeUserConsole: 'config/canSeeUserConsole',
      isStudentMachineLoading: 'labs/isLoading'
    }),
    pill () { return this.translateObject(this.requestedPill) },
    userLanguage () { return i18nservice.getActiveLanguage() },
    resources () { return typeof this.pill.resources !== 'undefined' ? this.pill.resources.filter(item => item.language === this.userLanguage) : [] },
    somethingIsLoading () { return this.loading || this.isLoading },
    quizProgress () {
      if (this.pillIsInComplete) return 100
      return this.form ? (this.correct / this.form.length) * 100 : 0
    },
    hasQuestions () { return typeof this.pill.questions !== 'undefined' && this.pill.questions.length > 0 },
    hasDescription () { return typeof this.pill.description !== 'undefined' && this.pill.description !== '' },
    hasResources () { return typeof this.resources !== 'undefined' && this.resources.length > 0 },
    isPracticePill () { return this.pill.type === PILL_TYPES.PRACTICE },
    shouldShowInfo () { return this.hasDescription || this.isPracticePill },
    showPillResources () { return this.resourceOrMachine === this.$t('TERMS.resources') },
    showPlayerMachine () { return this.resourceOrMachine === this.$t('TERMS.console') },

    // Máquina de lab, en caso de ser de tipo práctico
    machine () { return {} }, // TODO delete
    labId () { return -1 }, // TODO delete

    vms () { return typeof this.pill.vms !== 'undefined' ? this.pill.vms : [] },
    hasVms () { return this.vms.length > 0 },

    // Pill related properties
    pillId () { return this.$route.params.id },
    pillStatus () { return typeof this.pill.student_pill_completion !== 'undefined' ? this.pill.student_pill_completion.completion_status : '' },
    pillHasStatus () { return this.pillStatus !== '' },
    pillIsPending () { return !this.pillHasStatus || PILL_STATUS.PENDING === this.pillStatus },
    pillIsInProgress () { return this.pillHasStatus && PILL_STATUS.IN_PROGRESS === this.pillStatus },
    pillIsInComplete () { return this.pillHasStatus && PILL_STATUS.COMPLETE === this.pillStatus },

    submitButtonText () { return this.hasQuestions ? this.$t('ACTIONS.send') : this.$t('ACTIONS.finish') },

    resourceOrMachineOptions () { return this.canSeeUserConsole ? [this.$t('TERMS.resources'), this.$t('TERMS.console')] : [this.$t('TERMS.resources')] },

    hasMachineUrl () {
      return this.url !== null && this.url !== ''
    },
    hasPassedAwsChecks () {
      return this.consoleStatus === STUDENT_MACHINE_START_STATUS.RUNNING && this.consoleBoot === STUDENT_MACHINE_BOOT_STATUS.RUNNING
    },
    consoleUrl () { return this.hasMachineUrl && this.hasPassedAwsChecks ? this.url : '' },

    currentConsoleStatus () {
      if (this.consoleStartStatus === 'restarting') return this.$t('STUDENT_MACHINE.LOADING_STATUS.restarting')
      if (this.consoleStartStatus === 'reverting') return this.$t('STUDENT_MACHINE.LOADING_STATUS.reverting')
      if (this.consoleStartStatus === 'starting') return this.$t('STUDENT_MACHINE.LOADING_STATUS.starting')

      if (this.consoleStatus === STUDENT_MACHINE_START_STATUS.PENDING && this.consoleBoot === STUDENT_MACHINE_BOOT_STATUS.PENDING) return this.$t('STUDENT_MACHINE.LOADING_STATUS.starting')
      if (this.consoleStatus === STUDENT_MACHINE_START_STATUS.RUNNING && this.consoleBoot === STUDENT_MACHINE_BOOT_STATUS.IN_PROGRESS) return this.$t('STUDENT_MACHINE.LOADING_STATUS.starting_os')
      if (this.consoleStatus === STUDENT_MACHINE_START_STATUS.RUNNING && this.consoleBoot === STUDENT_MACHINE_BOOT_STATUS.RUNNING) return this.$t('STUDENT_MACHINE.LOADING_STATUS.ready')

      return this.$t('STUDENT_MACHINE.LOADING_STATUS.starting')
    },
    consoleKeepAliveTimeInterval () {
      return parseInt(process.env.VUE_APP_CONSOLE_LEEP_ALIVE_TIME_INTERVAL) || 5
    },

    pillDuration () {
      return typeof this.pill.duration !== 'undefined' ? this.pill.duration : 0
    }
  },
  mounted () {
    // Attempt to refocus iframe upon click or keydown
    document.addEventListener('click', this.refocusGuacamole)
    document.addEventListener('keydown', this.refocusGuacamole)

    this.getPillDetail(this.$route.params.id)
      .then(_ => {
        if (this.hasResources) {
          this.switchResource(0)
        } else if (this.hasVms) {
          this.switchVm(0)
        }

        this.form = this.translateObjectArray(
          this.pill.questions.map(item => {
            if (item.type === 'select' && typeof item.pill_question_options !== 'undefined') {
              item.pill_question_options = this.translateObjectArray(item.pill_question_options)
            }
            return { ...item, answer: '' }
          })
        )
      })

      .catch(err => {
        this.handleAxiosError(err)
      })

    this.consoleKeepAlive()

    this.$on('console-started', _ => {
      this.stopCheckConsole()
    })
  },

  beforeDestroy () {
    clearInterval(this.checkStatusIntervalId)
    clearInterval(this.keepAliveInterval)
    clearInterval(this.simulateProgressInterval)
  }

}
</script>

<style lang="scss" scoped>
.content-wrapper, .quiz-wrapper {
  transition: all 0.75s linear;
}
.content-wrapper {
  position: fixed;
  left: 0;
  width: 65%;
  top: $header-height;
  bottom: 0px;

  .video-wrapper {
    max-height: 100%;
  }

  &.expanded {
    width: 100%;
  }
}

.quiz-wrapper {
  overflow-x: hidden;
  position: fixed;
  right: 0;
  width: 35%;
  top: $header-height;
  bottom: 0px;
  background: $grey-light;
  padding: 0 24px;

  &.collapsed, &.floating {
    transform: translateX(100%);
  }
}

.pill-top-header {
  height: $header-height;
  background-color: var(--primary-dark);
  display: flex;
  flex-direction: row;

  .resources-tab-wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .resource-tab {
      text-align: center;
      min-width: 200px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 0 2rem;
      color: var(--primary-inverse);

      &:hover {
        color: var(--secondary-inverse);
        background-color: var(--secondary);

        svg {
          color: var(--secondary-inverse);
        }
      }

      &.active {
        font-weight: bold;

        svg {
          color: var(--secondary);
        }

        &:hover {
          svg {
            color: var(--secondary-inverse);
          }
        }
      }
    }
  }
}

.back-link {
  line-height: calc(#{$header-height} + 10px);
  margin-left: $space-big;
  color: white;
}

video {
  width: 100%;
  max-height: 100%;;
}

.focus-indicator {
  color: $red;
  z-index: 10;

  &.focus {
    color: $green;
  }
}

.btn-secondary {
    svg {
      fill: var(--secondary-inverse)
    }
}

.btn-outline-secondary {
  svg {
    transition: fill 0.15s ease-in-out;
  }

  &:hover {
    svg {
      fill: var(--secondary-inverse)
    }
  }
}
</style>
